import React from "react";
import { useEffect } from "react";
import { calculatePrice } from "../Utils/custom.js";
export default function Pricinglist() {
  useEffect(() => {
    calculatePrice();
  }, []);

  return (
    <>
      <section className="pricing">
        <div className="container">
          <div className="pricing-row">
            <div
              className="left"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <h2 className="main-heading-blue">
                Pick a plan that's <br />
                right for you{" "}
                <img
                  className="line"
                  src="assets/front/images/pricing/line.svg"
                  alt=""
                />
              </h2>
              {/* <p className="main-para">
                The special launch plan will be valid only until 12/29/2024.
                After that date, users must upgrade or downgrade to other plans.
              </p> */}
            </div>
            <div
              className="right"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="btn-group price-btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
                style={{ display: "none" }}
              >
                <input
                  value=""
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="pricemonth"
                  checked="checked"
                />
                <label
                  className="btn btn-outline-primary month active"
                  htmlFor="pricemonth"
                >
                  Monthly
                </label>

                <input
                  value=""
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="priceyear"
                />
                <label
                  className="btn btn-outline-primary year"
                  htmlFor="priceyear"
                >
                  Yearly <span className="badge">Save 20%</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="price-list-row ">
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <h3 className="price-cat">Starter</h3>
            {/* <p>Ideal for newer brokers who want to start with the basics.</p> */}
            <hr />

            <h2 className="price">
              $<b className="price-monthly">89</b>
              <b className="price-yearly d-none">79</b>
            </h2>

            <span>Per member, per Month</span>
            <hr />

            <ul className="price-listing">
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Daily Presale Dashboard
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Presale Codes
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Full Artist Metrics
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Ticket Counter Extension
                <span className="badge-success">Unlimited checks</span>
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Price Map Shader Extension
                <span className="badge-success">Unlimited</span>
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                10 Favorites with Full Primary, Secondary, and Sold Data
                Analytics
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                10 Alerts (Drop Checker, Low Inventory, and Price Drop)
              </li>
            </ul>

            <div className="btn-wrapper">
              <a
                className="pay-link-monthly"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
              <a
                className="pay-link-yearly d-none"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
            </div>
          </div>
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
            style={{ display: "none" }}
          >
            {/* <span className="badge-popular">Special Launch Plan</span> */}
            <h3 className="price-cat">Pro</h3>
            {/* <p>
              Our special launch plan includes unlimited data at a fraction of
              the price.
            </p> */}
            <hr />

            <h2 className="price ">
              $<b className="price-monthly">189</b>
              <b className="price-yearly d-none">125</b>
            </h2>

            <span>Per member, per Month</span>
            <hr />

            <ul className="price-listing">
              <li>Everything in the Starter plan, plus:</li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                100 Favorites with Full Primary, Secondary, and Sold Data
                Analytics
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                100 Alerts (Drop Checker, Low Inventory, and Price Drop)
                {/* <span className="badge-coming">Beta / Coming Soon</span> */}
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Ticket Counter Extension + Limited Data
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Primary Top Movers - Top 200 Moving Events
                <span className="badge-danger">limited</span>
              </li>
            </ul>

            <div className="btn-wrapper">
              <a
                className="pay-link-monthly"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
              <a
                className="pay-link-yearly d-none"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
            </div>
          </div>
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <h3 className="price-cat">Advanced</h3>
            {/* <p>
              For all advanced brokers ready to up their game through analytics
            </p> */}
            <hr />

            <h2 className="price ">
              $<b className="price-monthly">269</b>
              <b className="price-yearly d-none">125</b>
            </h2>

            <span>Per member, per Month</span>
            <hr />

            <ul className="price-listing">
              <li>Everything in the Pro plan, plus:</li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Unlimited Primary, Secondary, and Sold Data Analytics
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Ticket Counter Extension with Unlimited Data
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                300 Alerts (Drop Checker, Low Inventory, and Price Drop)
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                600 Favorite Events
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                SalesTIQ Reports - Top 200 Selling Events
                <span className="badge-danger">limited</span>
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Full Access to Primary Top-Movers
                <span className="badge-success">Unlimited</span>
              </li>
            </ul>
            <div className="btn-wrapper">
              <a
                className="pay-link-monthly"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
              <a
                className="pay-link-yearly d-none"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
            </div>
          </div>
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="professional-badges">
              <h3 className="price-cat">Premium</h3>
              {/* <span className="badge-popular">Popular</span> */}
            </div>

            {/* <p>For professionals ready to dominate the secondary market</p> */}
            <hr />

            <h2 className="price ">
              $<b className="price-monthly">379</b>
              <b className="price-yearly d-none">167</b>
            </h2>

            <span>Per member, per Month</span>
            <hr />

            <ul className="price-listing">
              <li>Everything in the Advanced plan, plus:</li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Comes with +1 seat
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Full Access to SalesTIQ Reports
                <span className="badge-success">Unlimited</span>
              </li>

              <li>
                <i className="fa-solid fa-circle-check"></i>
                SalesTIQ Reports Search Bar and Advance Filters
                <span className="badge-coming">Coming Soon</span>
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                400 Alerts (Drop Checker, Low Inventory, and Price Drop)
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                800 Favorite Events
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Advanced Filters
              </li>
            </ul>
            <div className="btn-wrapper">
              <a
                className="pay-link-monthly"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
              <a
                className="pay-link-yearly d-none"
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{
                  filter: "brightness(0.5)",
                  cursor: "help",
                  color: "#aaaaaa",
                  textDecoration: "none",
                  opacity: "0.7",
                }}
                title="Thanks for your interest! We're not accepting new users right now — check back after 4/3/25."
                onClick={(e) => e.preventDefault()}
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
        <div className="price-list-row ">
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <h3 className="price-cat">Premium Plus+</h3>
            {/* <p>Ideal for newer brokers who want to start with the basics.</p> */}
            <hr />

            <h2 className="price">
              $<b className="price-monthly">449</b>
              <b className="price-yearly d-none">79</b>
            </h2>

            <span>Per member, per Month</span>
            <hr />

            <ul className="price-listing">
              <li>Everything in the Premium plan, plus:</li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Comes with +2 seats
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Export daily sold data reports
                <span className="badge-coming">Coming Soon</span>
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Email reports
                <span className="badge-coming">Coming Soon</span>
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                500 Alerts (Drop Checker, Low Inventory, and Price Drop)
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                1,200 Favorite Events
              </li>
            </ul>
            <div className="btn-wrapper">
              <p>
                <a
                  href="mailto:support@ticketmetric.io"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>
              </p>
            </div>
          </div>
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <h3 className="price-cat">Team Collaboration Add-on</h3>
            {/* <p>
              Enhance your team's workflow with integrated features designed for
              collaboration
            </p> */}
            <hr />

            <h2 className="price ">
              $<b className="price-monthly">49</b>
              <b className="price-yearly d-none">125</b>
            </h2>

            <span>Per member, per Month</span>
            <hr />

            <ul className="price-listing">
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Integrated Team Favorites
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Shared Alerts Management
              </li>
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Streamlined Collaboration
              </li>
            </ul>

            <div className="btn-wrapper">
              <p>
                <a
                  href="mailto:support@ticketmetric.io"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>
              </p>
            </div>
          </div>
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="professional-badges">
              <h3 className="price-cat">Sales API</h3>
              {/* <span className="badge-popular">Popular</span> */}
            </div>

            <hr />

            <ul className="price-listing">
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Access our full catalog of all event sales data.
              </li>
            </ul>
            <div className="btn-wrapper">
              <p>
                <a
                  href="mailto:support@ticketmetric.io"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>
              </p>
            </div>
          </div>
          <div
            className="price-box"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <h3 className="price-cat">API Plus+</h3>
            <hr />

            <ul className="price-listing">
              <li>
                <i className="fa-solid fa-circle-check"></i>
                Full API Access
              </li>
            </ul>

            <div className="btn-wrapper">
              <p>
                <a
                  href="mailto:support@ticketmetric.io"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
