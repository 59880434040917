import React from "react";
import { useEffect } from "react";
import { counterNumbers } from "../Utils/custom";

export default function Counter() {
  useEffect(() => {
    counterNumbers();
  }, []);

  return (
    <>
      <section className="sec-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="main-heading" data-aos="fade-up">
                All the data you need in one <br /> advanced dashboard
              </h1>
            </div>
          </div>
        </div>
        <div className="sec-2-bg">
          <div className="container">
            <div className="row">
              <div className="counter-sec" id="counter">
                <div className="box-sec">
                  <div
                    className="box js-tilt"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <div className="bg ">
                      <img src="assets/front/images/sec-2/t-l.png" alt="" />
                      <img src="assets/front/images/sec-2/b-l.png" alt="" />
                      <img src="assets/front/images/sec-2/b-r.png" alt="" />
                      <img src="assets/front/images/sec-2/t-r.png" alt="" />
                    </div>
                    <h2 className="counter-heading" data-aos="fade-up">
                      <span data-target="30" className="counter">
                        0
                      </span>
                      k+
                    </h2>
                  </div>

                  <h3
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    Artists <br />
                    Metrics
                  </h3>

                  <img
                    className="arrow"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    src="assets/front/images/sec-2/Arrow1.png"
                    alt=""
                  />

                  <div
                    className="content "
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <p>
                      Discover detailed analytics, including audience location
                      data for each artist on platforms such as YouTube,
                      Spotify, Instagram, and more
                    </p>
                  </div>
                </div>
                <div className="box-sec">
                  <div
                    className="box js-tilt"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <div className="bg">
                      <img src="assets/front/images/sec-2/t-l.png" alt="" />
                      <img src="assets/front/images/sec-2/b-l.png" alt="" />
                      <img src="assets/front/images/sec-2/b-r.png" alt="" />
                      <img src="assets/front/images/sec-2/t-r.png" alt="" />
                    </div>
                    <h2 className="counter-heading" data-aos="fade-up">
                      <span data-target="110" className="counter">
                        0
                      </span>
                      k+
                    </h2>
                  </div>

                  <h3
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    Secondary Market <br />
                    Analytics
                  </h3>

                  <img
                    className="arrow"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    src="assets/front/images/sec-2/Arrow2.png"
                    alt=""
                  />

                  <div
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    className="content"
                  >
                    <p>
                      Gain an overview of the entire secondary market with
                      comprehensive secondary market data for each event,
                      complemented by in-depth analytics
                    </p>
                  </div>
                </div>
                <div className="box-sec">
                  <div
                    className="box js-tilt"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <div className="bg">
                      <img src="assets/front/images/sec-2/t-l.png" alt="" />
                      <img src="assets/front/images/sec-2/b-l.png" alt="" />
                      <img src="assets/front/images/sec-2/b-r.png" alt="" />
                      <img src="assets/front/images/sec-2/t-r.png" alt="" />
                    </div>
                    <h2 className="counter-heading" data-aos="fade-up">
                      <span data-target="50" className="counter">
                        0
                      </span>
                      k+
                    </h2>
                  </div>

                  <h3
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    Primary Market <br />
                    Analytics
                  </h3>

                  <img
                    className="arrow"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    src="assets/front/images/sec-2/Arrow3.png"
                    alt=""
                  />

                  <div
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    className="content"
                  >
                    <p>
                      Explore the primary market landscape with detailed primary
                      event data, providing you with a complete overview and
                      thorough analytics for each event
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
