import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PriceAndTiqBanner from "../components/PricingAndTiqBanner";
import SalesTIQ from "../components/SalesTIQ";

export default function SalesTIQPage() {
  return (
    <>
      <Helmet>
        <title>The Most Advanced Extension</title>
        <meta
          name="description"
          content="Researching events has never been this easy! The best tool for finding opportunities in the secondary market."
        />
      </Helmet>
      <Navbar />
      <PriceAndTiqBanner />
      <SalesTIQ />

      <Footer />
    </>
  );
}
