import React from "react";
import Navbar from "../components/Navbar";
import PriceAndTiqBanner from "../components/PricingAndTiqBanner";
import Pricinglist from "../components/Pricinglist";
import Calltoaction from "../components/Calltoaction";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function PricingPage() {
  return (
    <>
      <Helmet>
        <title>The Most Advanced Extension</title>
        <meta
          name="description"
          content="Researching events has never been this easy! The best tool for finding opportunities in the secondary market."
        />
      </Helmet>
      <Navbar />
      <PriceAndTiqBanner />
      <Pricinglist />
      <Calltoaction />
      <Footer />
    </>
  );
}
