import React from "react";

export default function SalesTIQ() {
  return (
    <div className="salestiq-wrapper">
      <div className="container">
        <div className="content-wrapper">
          <div className="text-content">
            <h2 className="main-heading">SalesTIQ</h2>
            <h3 className="sub-heading">Never Miss a Single Moving Event</h3>
          </div>
          <div className="image-section">
            <div className="image-container">
              <img
                className="front js-tilt"
                loading="lazy"
                src="assets/front/images/salestiq/1.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <section className="sec-primary-analytics">
        <div className="container">
          <div className="salestiq-features-bg">
            <div className="salestiq-features-content">
              <div className="row mb-5">
                <div className="col-12 text-center">
                  <h2
                    className="main-heading-2 mx-auto"
                    data-aos="fade-down"
                    data-aos-duration="3000"
                    style={{ color: "white" }}
                  >
                    SalesTIQ Features
                  </h2>
                </div>
              </div>
              <div className="row g-4 justify-content-center">
                <div className="col-lg-3 col-md-6">
                  <div className="feature-card">
                    <div className="icon-wrapper">
                      <img
                        src="assets/front/images/salestiq/reports.svg"
                        alt="Reports"
                        className="feature-icon"
                      />
                    </div>
                    <h3>Top-Selling Reports</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="feature-card">
                    <div className="icon-wrapper">
                      <img
                        src="assets/front/images/salestiq/filters.svg"
                        alt="Filters"
                        className="feature-icon"
                      />
                    </div>
                    <h3>Advanced Filtering & Search Bars</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="feature-card">
                    <div className="icon-wrapper">
                      <img
                        src="assets/front/images/salestiq/trends.svg"
                        alt="Trends"
                        className="feature-icon"
                      />
                    </div>
                    <h3>Active & Sold Data Trends</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="feature-card">
                    <div className="icon-wrapper">
                      <img
                        src="assets/front/images/salestiq/sales.svg"
                        alt="Sales"
                        className="feature-icon"
                      />
                    </div>
                    <h3>Sales Breakdown</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style jsx>{`
        .salestiq-wrapper {
          background: linear-gradient(270deg, #0e137e 0%, #000000 64.58%);
          min-height: 100vh;
          width: 100%;
          position: relative;
          padding: 4rem 0;
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;
        }

        .text-content {
          text-align: center;
          width: 100%;
          max-width: 800px;
        }

        .image-section {
          width: 100%;
          max-width: 1000px;
          position: relative;
        }

        .main-heading {
          color: #ffffff;
          text-align: center;
          margin-bottom: 1rem;
          font-size: 2.1rem;
        }

        .main-heading-2 {
          color: #ffffff;
          text-align: center;
          margin-bottom: 1rem;
          font-size: 1.8rem;
        }

        .sub-heading {
          color: #707573;
          font-size: 1.2rem;
          margin-bottom: 0;
        }

        .image-container {
          position: relative;
          width: 100%;
          padding-bottom: 2rem;
        }

        .moving-images img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }

        .front {
          position: relative;
          z-index: 1;
          width: 100%;
        }

        .back {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          z-index: 0;
          opacity: 0.6;
        }

        .sec-primary-analytics {
          padding-top: 4rem;
        }

        .sec-features {
          padding-top: 20px;
        }

        .salestiq-features-bg {
          background: none;
          border-radius: 24px;
          padding: 4rem 2rem;
          position: relative;
          overflow: hidden;
          text-align: center;
        }

        .salestiq-features-content {
          position: relative;
          z-index: 1;
          max-width: 1200px;
          margin: 0 auto;
          background: none;
        }
        .feature-card {
          background: #20186a;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          /* padding: 2rem; */
          padding-right: 4rem;
          padding-left: 4rem;
          width: 243.83px;
          height: 170.69px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          transition: transform 0.3s ease;
          margin: 0 auto;
          position: relative;
          overflow: hidden;
        }
        .feature-card:hover {
          transform: translateY(-5px);
        }
        .icon-wrapper {
          width: 40px;
          height: 40px;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
        }
        .feature-icon {
          width: 100%;
          height: auto;
          filter: brightness(0) invert(1);
        }
        .feature-card h3 {
          color: white;
          font-size: 0.8rem;
          font-weight: 500;
          margin: 0;
          position: relative;
          z-index: 1;
        }

        /* Add smooth scrolling to html */
        :global(html) {
          scroll-behavior: smooth;
        }
      `}</style>
    </div>
  );
}
